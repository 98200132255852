import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import {
	PageInit,
	BypassIfAuthInit,
	AdminPageInit,
	OrgPageInit,
	AuthPageInit,
} from 'components/HigherOrder/PageInit';

/* loader component for Suspense*/
import PageLoader from 'components/Theme/Common/PageLoader';

import Base from 'components/Theme/Layout/Base';
import BasePage from 'components/Theme/Layout/BasePage';

import DefaultRedirect from './DefaultRedirect';
// List of routes that uses the unknown user page layout
// listed here to Switch between layouts
// depending on the current pathname
import * as routePaths from './paths';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props}/>;

// UNKNOWN USER PAGES
const Login = lazy(() => import('components/Pages/Unknown/Login'));
const Logout = lazy(() => import('components/Pages/Unknown/Logout'));
const PasswordRecover = lazy(() => import('components/Pages/Unknown/PasswordRecover'));
const PasswordReset = lazy(() => import('components/Pages/Unknown/PasswordReset'));
const AdminAccept = lazy(() => import('components/Pages/Unknown/AdminAccept'));

// SUPER ADMIN PAGES
const AdminDashboard = lazy(() => import('components/Pages/Authorized/Admin/Dashboard'));
const AdminsList = lazy(() => import('components/Pages/Authorized/Admin/Admins/List'));
const AdminsDetail = lazy(() => import('components/Pages/Authorized/Admin/Admins/Detail'));
const OrganizationsList = lazy(() => import('components/Pages/Authorized/Admin/Organizations/List'));
const OrganizationsDetail = lazy(() => import('components/Pages/Authorized/Admin/Organizations/Detail'));
const OrganizationsAddNew = lazy(() => import('components/Pages/Authorized/Admin/Organizations/AddNew'));
const OrganizationsUserDetail = lazy(() => import('components/Pages/Authorized/Admin/Organizations/UserDetail'));


// ORG PAGES
const OrgDashboard = lazy(() => import('components/Pages/Authorized/Org/Dashboard'));
const OrgColors = lazy(() => import('components/Pages/Authorized/Org/Colors/Detail'));
const OrgContent = lazy(() => import('components/Pages/Authorized/Org/Content/Detail'));
const OrgDonationsList = lazy(() => import('components/Pages/Authorized/Org/Donations/List'));
const OrgDonationsDetail = lazy(() => import('components/Pages/Authorized/Org/Donations/Detail'));
const OrgProgramsList = lazy(() => import('components/Pages/Authorized/Org/Programs/List'));
const OrgProgramsDetail = lazy(() => import('components/Pages/Authorized/Org/Programs/Detail'));
const OrgProgramsAddNew = lazy(() => import('components/Pages/Authorized/Org/Programs/AddNew'));
const OrgSettings = lazy(() => import('components/Pages/Authorized/Org/Settings/Detail'));
const OrgUsers = lazy(() => import('components/Pages/Authorized/Org/Users/List'));

// OTHER PAGES
const SelectOrg = lazy(() => import('components/Pages/Authorized/SelectOrg'));

const Routes = ({ location }) => {
	const currentKey = location.pathname.split('/')[1] || '/';
	const timeout = { enter: 500, exit: 500 };

	// Animations supported
	//      'rag-fadeIn'
	//      'rag-fadeInRight'
	//      'rag-fadeInLeft'

    const animationName = 'rag-fadeIn'

	if(routePaths.UNKNOWN_USER_PATHS.indexOf(location.pathname) > -1) {
		return (
			// Page Layout component wrapper
			<BasePage>
				<Suspense fallback={<PageLoader/>}>
					<Switch location={location}>
						<Route path={ routePaths.getPathLogin() } component={waitFor(BypassIfAuthInit(Login))}/>
						<Route path={ routePaths.getPathLogout() } component={waitFor(PageInit(Logout))}/>
						<Route path={ routePaths.getPathPasswordRecover() } component={waitFor(BypassIfAuthInit(PasswordRecover))}/>
						<Route path={ routePaths.getPathPasswordReset() } component={ waitFor(PageInit(PasswordReset)) }/>
						<Route path={ routePaths.getPathAdminAccept() } component={ waitFor(PageInit(AdminAccept)) }/>
					</Switch>
				</Suspense>
			</BasePage>
		)
	}
	else {
		return (
			<Base>
				<TransitionGroup>
					<CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
						<div>
							<Suspense fallback={<PageLoader/>}>
								<Switch location={location}>
									<Route path={ routePaths.getPathAdminDashboard() } exact component={waitFor(AdminPageInit(AdminDashboard))}/>
									<Route path={ routePaths.getPathOrganizations() } exact component={ waitFor(AdminPageInit(OrganizationsList)) } />
									<Route path={ routePaths.getPathOrganizationsAddNew() } exact component={ waitFor(AdminPageInit(OrganizationsAddNew)) } />
									<Route path={ routePaths.getPathOrganizationsUserDetail(':adminOrgKey', ':key') } exact component={ waitFor(AdminPageInit(OrganizationsUserDetail)) } />
									<Route path={ routePaths.getPathOrganizations(':key') } exact component={ waitFor(AdminPageInit(OrganizationsDetail)) } />
									<Route path={ routePaths.getPathSystemAdmins() } exact component={ waitFor(AdminPageInit(AdminsList)) } />
									<Route path={ routePaths.getPathSystemAdmins(':key') } exact component={ waitFor(AdminPageInit(AdminsDetail)) } />

									<Route path={ routePaths.getPathOrgColors(':orgKey') } exact component={waitFor(OrgPageInit(OrgColors))}/>
									<Route path={ routePaths.getPathOrgContent(':orgKey') } exact component={waitFor(OrgPageInit(OrgContent))}/>
									<Route path={ routePaths.getPathOrgDonations(':orgKey') } exact component={waitFor(OrgPageInit(OrgDonationsList))}/>
									<Route path={ routePaths.getPathOrgDonations(':orgKey', ':key') } exact component={waitFor(OrgPageInit(OrgDonationsDetail))}/>
									<Route path={ routePaths.getPathOrgPrograms(':orgKey') } exact component={waitFor(OrgPageInit(OrgProgramsList))}/>
									<Route path={ routePaths.getPathOrgWhitelabelProgramsAddNew(':orgKey', ':whitelabelKey') } exact component={waitFor(OrgPageInit(OrgProgramsAddNew))}/>
									<Route path={ routePaths.getPathOrgWhitelabelPrograms(':orgKey', ':whitelabelKey', ':key') } exact component={waitFor(OrgPageInit(OrgProgramsDetail))}/>
									<Route path={ routePaths.getPathOrgDashboard(':orgKey') } exact component={waitFor(OrgPageInit(OrgDashboard))}/>
									<Route path={ routePaths.getPathOrgSettings(':orgKey') } exact component={waitFor(OrgPageInit(OrgSettings))}/>
									<Route path={ routePaths.getPathOrgUsers(':orgKey') } exact component={waitFor(OrgPageInit(OrgUsers))}/>

									<Route path={ routePaths.getPathSelectOrg() } exact component={waitFor(AuthPageInit(SelectOrg))}/>

									<DefaultRedirect />
								</Switch>
							</Suspense>
						</div>
					</CSSTransition>
				</TransitionGroup>
			</Base>
		)
	}
}

export default withRouter(Routes);
