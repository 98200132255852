import {
	getAll,
	getSingle,
	getOrganizationEndpoint,
} from 'utils/api';

const END_POINT = 'donations';
const ACTION_ROOT = 'donations';

/* ACTIONS */
const SET_LIST 								= `${ACTION_ROOT}/SET_LIST`;
const SET_ACTIVE 							= `${ACTION_ROOT}/SET_ACTIVE`;
const CLEAR_ACTIVE 						= `${ACTION_ROOT}/CLEAR_ACTIVE`;

const initialState = {
	list: [],
	active: {},
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_LIST:
			return { ...state, list: action.payload };
		case SET_ACTIVE:
			return { ...state, active: action.payload, editState: false }
		case CLEAR_ACTIVE:
			return { ...state, active: {} }
		default:
			return state;
	}
}

export function getAllDonations( orgKey, success ) {
	return function( dispatch ) {
		getAll(
			getOrganizationEndpoint( orgKey, END_POINT ),
			dispatch,
			SET_LIST,
			success
		);
	}
}

export function clearActiveDonation() {
	return {
		type: CLEAR_ACTIVE,
	}
}

export function getActiveDonation( orgKey, key ) {
	return function( dispatch ) {
		getSingle(
			getOrganizationEndpoint( orgKey, END_POINT ),
			key,
			dispatch,
			SET_ACTIVE
		);
	}
}
