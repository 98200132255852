import {reset} from 'redux-form';

import { getCurrentBaseUrl } from 'utils';
import { getPathAdminAccept } from 'Routes/paths';
import * as labels from 'data/labels';

import {
	getAll,
	getSingle,
	createNew,
	updateItem,
	showSuccessMessage,
	putData,
	deleteData,
} from 'utils/api';

const ACTION_ROOT = 'users';
const MSG_SAVE_SUCCESS = 'User Saved';

const SET_LIST 								= `${ACTION_ROOT}/SET_LIST`;
const SET_ACTIVE 							= `${ACTION_ROOT}/SET_ACTIVE`;
const CLEAR_ACTIVE 						= `${ACTION_ROOT}/CLEAR_ACTIVE`;
const SET_ACTIVE_EDIT_STATE 	= `${ACTION_ROOT}/SET_ACTIVE_EDIT_STATE`;

const initialState = {
	list: [],
	active: {},
	editState: false,
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_LIST:
			return { ...state, list: action.payload };
		case CLEAR_ACTIVE:
			return { ...state, active: {} }
		case SET_ACTIVE:
			return { ...state, active: action.payload, editState: false }
		case SET_ACTIVE_EDIT_STATE:
			return { ...state, editState: action.payload }
		default:
			return state;
	}
}

function apiRequestUsers( endpoint, dispatch ) {
	getAll(
		endpoint,
		dispatch,
		SET_LIST,
	);
}

function apiRequestUser( userKey, dispatch ) {
	getSingle(
		'/admin/users',
		userKey,
		dispatch,
		SET_ACTIVE,
	);
}

function apiRequestFilteredUsers( filters={}, dispatch ) {
	if( filters.orgKey ) {
		// const endpoint = getOrganizationEndpoint( filters.orgKey, 'users' );
		const endpoint = `organizations/${filters.orgKey}/users`;		// TODO: request changing endpoint to be "organization" to match other endpoints
		apiRequestUsers(endpoint, dispatch);
		return;
	}
	else if( filters.role ) {
		const endpoint = `roles/${filters.role}/users`;
		apiRequestUsers(endpoint, dispatch);
		return;
	}
	else {
		const endpoint = `admin/users/all`;
		apiRequestUsers(endpoint, dispatch);
		return;
	}
}

export function getAllUsers() {
	return function( dispatch ) {
		const endpoint = `admin/users/all`;
		apiRequestUsers(endpoint, dispatch);
	}
}

export function getUsersInOrg(orgKey) {
	return function(dispatch) {
		apiRequestFilteredUsers({ orgKey }, dispatch);
	}
}

export function getUsersByRole( role ) {
		return function( dispatch ) {
			const endpoint = `roles/${role}/users`;
			apiRequestUsers(endpoint, dispatch);
		}
}

export function getFilteredUsers( filters ) {
	return function( dispatch ) {
		apiRequestFilteredUsers( filters, dispatch );
	}
}

export function setActiveUserEditState( active ) {
	return {
		type: SET_ACTIVE_EDIT_STATE,
		payload: active,
	}
}

export function clearActiveUser() {
	return function( dispatch ) {
		dispatch({
				type: CLEAR_ACTIVE,
		});
	}
}

export function getActiveUser( userKey ) {
	return function( dispatch ) {
		apiRequestUser( userKey, dispatch );
	}
}

export function createUser( data, formName, listFilters ) {
	return function(dispatch) {
		createNew(
			'users',
			data,
			dispatch,
			SET_ACTIVE,
			MSG_SAVE_SUCCESS,
			(response) => {
				apiRequestFilteredUsers(listFilters, dispatch);
			},
			null,
			formName,
		);
	}
}

export function updateActiveUser( key, data, formName, listFilters ) {
	return function(dispatch) {
		updateItem(
			'users',
			key,
			data,
			dispatch,
			null,
			MSG_SAVE_SUCCESS,
			(response) => {
				(dispatch)(getActiveUser(key));
				apiRequestFilteredUsers(listFilters, dispatch);
			},
			null,
			formName,
		);
	}
}

export function inviteAdminUser( formData, formName, listFilters, success ) {
	return function(dispatch) {
		const data = {
			...formData,
			url: `${getCurrentBaseUrl()}${getPathAdminAccept()}`
		};

		putData(
			'admin/users/promote',
			null,
			data,
			dispatch,
			null,
			(response) => {
				apiRequestFilteredUsers(listFilters, dispatch);
				showSuccessMessage(`${data.emailAddress} has been invited to become an admin.`);
				if( formName ) {
					dispatch( reset(formName) );
				}

				if ( success ) {
					success(response);
				}
			},
		);
	}
}

export function removeAdminRole( userKey, listFilters, success ) {
	return function(dispatch) {
		deleteData(
			"admin/users/demote",
			userKey,
			dispatch,
			(response) => {
				apiRequestUser( userKey, dispatch );
				apiRequestFilteredUsers(listFilters, dispatch);
				showSuccessMessage(`The user is no longer an admin.`);

				if (success) {
					success(response);
				}
			},
		);
	}
}

export function addAdminRole( userEmail, userKey, listFilters ){
	return function(dispatch) {
		const data = {
			emailAddress: userEmail,
		};

		putData(
			'admin/users/promote',
			null,
			data,
			dispatch,
			null,
			(response) => {
				apiRequestUser( userKey, dispatch );
				apiRequestFilteredUsers(listFilters, dispatch);
				showSuccessMessage("The user has been promoted.")
			},
		);
	}
}

export function removeUserFromOrg(organizationKey, userKey, listFilters, success) {
	return function(dispatch) {

		// const endpoint = getOrganizationEndpoint( organizationKey, 'users' );
		const endpoint = `organizations/${organizationKey}/users`;		// TODO: request changing endpoint to be "organization" to match other endpoints

		deleteData(
			endpoint,
			userKey,
			dispatch,
			(response) => {
				apiRequestFilteredUsers(listFilters, dispatch);
				showSuccessMessage(`The user has been removed from this ${labels.Organization.toLowerCase()}.`);
				if (success) {
					success(response);
				}
			},
		);

	}
}

export function inviteUserToOrg( organizationKey, formData, formName, listFilters, success ) {
	return function(dispatch) {
		const data = {
			...formData,
			url: `${getCurrentBaseUrl()}/admin-accept`
		};

		// const endpoint = getOrganizationEndpoint( organizationKey, 'users/invite' );
		const endpoint = `organizations/${organizationKey}/users/invite`;		// TODO: request changing endpoint to be "organization" to match other endpoints

		putData(
			endpoint,
			null,
			data,
			dispatch,
			null,
			(response) => {
				apiRequestFilteredUsers(listFilters, dispatch);
				showSuccessMessage(`${data.emailAddress} has been invited.`);
				if( formName ) {
					dispatch( reset(formName) );
				}
				if (success) {
					success(response);
				}
			},
		);
	}
}
