export function getCurrentBaseUrl() {
	const {
		protocol,
		host,
	} = window.location;

	return `${protocol}//${host}`;
}

export function getValueFromKey( data, fieldKey ) {
	if ( !fieldKey || !data ) {
		return data;
	}

	let value = data;
	const keys = fieldKey.split(".");

	for( let i=0; i<keys.length; i++ ) {
		const key = keys[i];
		if( typeof value[key] === "undefined" ) {
			return null;
		}

		value = value[key];
	}

	return value;
}

export function formatNumber(amount, decimals=2) {
	if ( ! amount && amount !== 0 ) {
		return amount;
	}

	return (amount).toLocaleString('en', {minimumFractionDigits: decimals, maximumFractionDigits: decimals});
}

export function formatMoney(amount, decimals=2) {
	if ( ! amount && amount !== 0 ) {
		return amount;
	}

	return '$' + formatNumber( amount, decimals );
}

export function formatPenniesToMoney(pennies, decimals=2) {
	if ( ! pennies && pennies !== 0 ) {
		return pennies;
	}

	return formatMoney( pennies / 100, decimals);
}

export function showError({ touched, invalid }) {
	return ( touched && invalid );
}

export function formatFullName(firstName, lastName) {
	const names = [firstName, lastName];
	const populated = names.filter( (name) => name);
	return populated.join(" ");
}

export function formatPhone(number) {
	if ( ! number ) {
		return number;
	}

	const cleaned = ('' + number).replace(/\D/g, '');
	const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
	if ( ! match ) {
		return number;
	}

	return '(' + match[1] + ') ' + match[2] + '-' + match[3];
}
