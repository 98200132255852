import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from 'store/modules/auth';
import { isAdmin } from 'utils/user';
import { getActiveOrgs } from 'utils/org';

export const ParamsInit = (ComposedComponent) => {
	class InitParams extends Component {
		static propTypes = {
			params: PropTypes.shape({
				orgKey: PropTypes.string,
			}),
		};

		componentDidMount(){
			const { currentUser } = this.props;

			// IF CURRENTUSER ISN'T LOADED, IT'S BECAUSE USER ISN'T AUTHENTICATED - DON'T INIT DATA
			if( ! currentUser || ! currentUser.key ) {
				return;
			}

			this.checkOrgKey();
		}

		checkOrgKey() {
			const {
				currentOrg,
				currentUser,
				setCurrentOrg,
				setCurrentOrgWithOrg,
				clearCurrentOrg,
				match: {
					params: {
						orgKey,
					},
				},
			} = this.props;

			// INITIALIZE CURRENT ORG FROM ORG KEY
			if( orgKey ) {
				if (  ! currentOrg || currentOrg.key !== orgKey ) {
					if ( currentUser && currentUser.key ) {

						if( isAdmin( currentUser.roles ) ) {
							setCurrentOrg( orgKey );
							return;
						}

						const attachedOrg = currentUser.organizations.find( (org) => org.key === orgKey );
						if( attachedOrg ) {
							setCurrentOrgWithOrg( attachedOrg );
							return;
						}
					}

					const org = {
						key: orgKey,
						name: '',
					}
					setCurrentOrgWithOrg( org );

				}
			}
			else {
				const activeOrgs = getActiveOrgs(currentUser.organizations);
				if( ! isAdmin( currentUser.roles ) && activeOrgs.length === 1 ) {
					if (  ! currentOrg || currentOrg.key !== activeOrgs[0].key ) {
						setCurrentOrgWithOrg( activeOrgs[0] );
					}
				}
				else if( currentOrg.key ) {
					clearCurrentOrg();
				}
			}
		}

		render() {
			return <ComposedComponent {...this.props} />;
		}
	}

	function mapStateToProps(state) {
		return {
			currentOrg: state.auth.currentOrg,
			currentGivingEvent: state.auth.currentGivingEvent,
			availableGivingEvents: state.auth.availableGivingEvents,
			currentGivingEventOrg: state.auth.currentGivingEventOrg,
			currentUser: state.auth.currentUser,
		}
	}

	return connect(mapStateToProps, actions)(InitParams);
}
