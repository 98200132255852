import {
	getAll,
	getSingle,
	createNew,
	updateItem,
	deleteItem,
	getOrgWhitelabelEndpoint,
} from 'utils/api';

const END_POINT = 'programs';
const ACTION_ROOT = 'programs';

const MSG_SAVE_SUCCESS = 'Program Saved';
const MSG_DELETE_SUCCESS = 'Program Deleted';

/* ACTIONS */
const SET_LIST 								= `${ACTION_ROOT}/SET_LIST`;
const SET_ACTIVE 							= `${ACTION_ROOT}/SET_ACTIVE`;
const CLEAR_ACTIVE 						= `${ACTION_ROOT}/CLEAR_ACTIVE`;
const SET_ACTIVE_EDIT_STATE 	= `${ACTION_ROOT}/SET_ACTIVE_EDIT_STATE`;

const initialState = {
	list: [],
	active: {},
	editState: false,
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_LIST:
			return { ...state, list: action.payload };
		case SET_ACTIVE:
			return { ...state, active: action.payload, editState: false }
		case CLEAR_ACTIVE:
			return { ...state, active: {} }
		case SET_ACTIVE_EDIT_STATE:
			return { ...state, editState: action.payload }
		default:
			return state;
	}
}

export function getAllPrograms( orgKey, whitelabelKey, success ) {
	return function( dispatch ) {
		getAll(
			getOrgWhitelabelEndpoint( orgKey, whitelabelKey, END_POINT ),
			null,
			null,
			(response) => {
				const programs = response.data.map( (program) => transformDetailsPriceCents(program) );

				dispatch({
					type: SET_LIST,
					payload: programs,
				});

				if (success) {
					success(success);
				}
			}
		);
	}
}

export function createProgram( orgKey, whitelabelKey, data, formName, success ) {
	return function(dispatch) {
		const newData = addDetailsPriceCents(data);

		createNew(
			getOrgWhitelabelEndpoint( orgKey, whitelabelKey, END_POINT ),
			newData,
			dispatch,
			SET_ACTIVE,
			MSG_SAVE_SUCCESS,
			success,
			SET_LIST,
			formName,
		);
	}
}

export function setActiveProgramEditState( active ) {
	return {
		type: SET_ACTIVE_EDIT_STATE,
		payload: active,
	}
}

export function clearActiveProgram() {
	return {
		type: CLEAR_ACTIVE,
	}
}

export function getActiveProgram( orgKey, whitelabelKey, key ) {
	return function( dispatch ) {
		getSingle(
			getOrgWhitelabelEndpoint( orgKey, whitelabelKey, END_POINT ),
			key,
			null,
			null,
			(response) => {
				const program = transformDetailsPriceCents(response.data);

				dispatch({
					type: SET_ACTIVE,
					payload: program,
				});
			}
		);
	}
}

export function updateActiveProgram( orgKey, whitelabelKey, key, data, formName, success ) {
	return function(dispatch) {
		const newData = addDetailsPriceCents(data);

		updateItem(
			getOrgWhitelabelEndpoint( orgKey, whitelabelKey, END_POINT ),
			key,
			newData,
			dispatch,
			null,
			MSG_SAVE_SUCCESS,
			(response) => {
				const program = transformDetailsPriceCents(response.data);

				dispatch({
					type: SET_ACTIVE,
					payload: program,
				});
				
				if(success) {
					success(response);
				}
			},
			null,
			formName,
		);
	}
}

export function deleteProgram( orgKey, whitelabelKey, key, success ) {
	return function(dispatch) {
		deleteItem(
			getOrgWhitelabelEndpoint( orgKey, whitelabelKey, END_POINT ),
			key,
			dispatch,
			MSG_DELETE_SUCCESS,
			success,
			SET_LIST,
		);
	}
}

function transformDetailsPriceCents(program) {
	const {
		detailsPriceCents,
		...restProgram
	} = program;


	const priceKeyValue = (detailsPriceCents) ? detailsPriceCents : {};
	const transformedDetailsPriceCents = Object.entries(priceKeyValue).map( (value) => ({
		label: value[0],
		amountInCents: value[1],
	}))

	return {
		...restProgram,
		transformedDetailsPriceCents,
	};
}

function addDetailsPriceCents(program) {
	const {
		transformedDetailsPriceCents,
		...restProgram
	} = program;

	const priceArray = (transformedDetailsPriceCents) ? transformedDetailsPriceCents : [];
	let detailsPriceCents = {};
	priceArray.forEach((price) => {
		const {
			label,
			amountInCents,
		} = price;

		detailsPriceCents[label] = amountInCents;
	});

	return {
		...restProgram,
		detailsPriceCents,
	}
}
