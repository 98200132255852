import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { AUTH_USER, getCurrentUser } from 'store/modules/auth';

import App from './App';
import * as serviceWorker from './serviceWorker';

import configureStore from './store/store';
const store = configureStore();

function init() {
	const token = localStorage.getItem( 'token' );
	if( token ) {
		store.dispatch( { type: AUTH_USER } );
		getCurrentUser(
			store.dispatch,
			() => {
				renderApp();   // WAIT TO RENDER APP UNTIL API HAS RESPONDED WITH USER INFO
			},
			() => { renderApp(); }, // IF API RETURNS ERROR, STILL RENDER APP
		);
	}
	else {
		renderApp();
	}
}

function renderApp() {
	ReactDOM.render(
		<Provider store={store}>
			<App />
		</Provider>,
		document.getElementById('app')
	);
}

init();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
