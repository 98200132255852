export const PATH_ADD_NEW = 'add-new';
export const PATH_ADMIN = 'admin';
export const PATH_ADMIN_ACCEPT = 'admin-accept';
export const PATH_COLORS = 'colors';
export const PATH_CONTENT = 'content';
export const PATH_DASHBOARD = 'dashboard';
export const PATH_DONATIONS = 'donations';
export const PATH_LOGIN = 'login';
export const PATH_LOGOUT = 'logout';
export const PATH_ORG = 'org';
export const PATH_ORGANIZATIONS = 'organizations';
export const PATH_PASSWORD_RESET = 'password-reset';
export const PATH_PROGRAMS = 'programs';
export const PATH_RECOVER = 'recover';
export const PATH_REGISTER = 'register';
export const PATH_SELECT_ORG = 'select-org';
export const PATH_SETTINGS = 'settings';
export const PATH_SYSTEM_ADMINS = 'system-admins';
export const PATH_USERS = 'users';
export const PATH_WHITELABEL = 'whitelabel';

function throwIfMissing() {
	throw new Error('Missing parameter');
}

export function getPath( nodes ) {
	const path = nodes.toString().replace(/,/g, "/");
	return `/${path}`;
}

export function getAdminPath( nodes, key ) {
	let path = `/${PATH_ADMIN}`
					+ getPath( nodes )
					;

	if( key ) {
		path += `/${key}`;
	}

	return path;
}

export function getOrgPath( nodes, orgKey, key ) {
	let path = `/${PATH_ORG}/${orgKey}`
					+ getPath( nodes )
					;

	if( key ) {
		path += `/${key}`;
	}

	return path;
}

export function getOrgWhitelabelPath( nodes, orgKey, whiteLabelKey, key ) {
	let path = getOrgPath([], orgKey)
					+ `/${PATH_WHITELABEL}/${whiteLabelKey}`
					+ getPath( nodes )
					;

	if( key ) {
		path += `/${key}`;
	}

	return path;
}

// UNKNOWN USER PATHS
export function getPathLogin() {                    return getPath(PATH_LOGIN); }
export function getPathLogout() {                   return getPath(PATH_LOGOUT); }
export function getPathRegister() {                 return getPath(PATH_REGISTER); }
export function getPathPasswordRecover() {          return getPath(PATH_RECOVER); }
export function getPathPasswordReset() {            return getPath(PATH_PASSWORD_RESET); }
export function getPathAdminAccept() {              return getPath(PATH_ADMIN_ACCEPT); }

export function getPathUnknownHome() {
	return getPathLogin();
}

export const UNKNOWN_USER_PATHS = [
	getPathLogin(),
	getPathLogout(),
	// getPathRegister(),
	getPathPasswordRecover(),
	getPathPasswordReset(),
	getPathAdminAccept(),
];

// ADMIN USER PATHS
export function getPathAdminHome() {
	return getPathOrganizations();
}

export function getPathAdminDashboard() {
	return getAdminPath(PATH_DASHBOARD);
}

export function getPathSystemAdmins( key ) {
	return getAdminPath(PATH_SYSTEM_ADMINS, key);
}

export function getPathOrganizations( key ) {
	return getAdminPath(PATH_ORGANIZATIONS, key);
}

export function getPathOrganizationsAddNew() {
	return getAdminPath(PATH_ORGANIZATIONS, PATH_ADD_NEW);
}

export function getPathOrganizationsUserDetail( orgKey, userKey ) {
	return getAdminPath([
		PATH_ORGANIZATIONS,
		orgKey,
		PATH_USERS,
	], userKey);
}

// ORG USER PATHS
export function getPathOrgHome(orgKey=throwIfMissing()) {
	return getPathOrgPrograms(orgKey);
}

export function getPathOrgColors(orgKey=throwIfMissing()) {
	return getOrgPath(PATH_COLORS, orgKey);
}

export function getPathOrgContent(orgKey=throwIfMissing()) {
	return getOrgPath(PATH_CONTENT, orgKey);
}

export function getPathOrgDashboard(orgKey=throwIfMissing()) {
	return getOrgPath(PATH_DASHBOARD, orgKey);
}

export function getPathOrgDonations(orgKey=throwIfMissing(), key) {
	return getOrgPath(PATH_DONATIONS, orgKey);
}

export function getPathOrgPrograms(orgKey=throwIfMissing(), key) {
	return getOrgPath(PATH_PROGRAMS, orgKey, key);
}

export function getPathOrgWhitelabelPrograms(orgKey=throwIfMissing(), whitelabelKey=throwIfMissing(),key) {
	return getOrgWhitelabelPath(PATH_PROGRAMS, orgKey, whitelabelKey, key);
}

export function getPathOrgWhitelabelProgramsAddNew(orgKey=throwIfMissing(), whitelabelKey=throwIfMissing()) {
	return getOrgWhitelabelPath(PATH_PROGRAMS, orgKey, whitelabelKey, PATH_ADD_NEW);
}

export function getPathOrgSettings(orgKey=throwIfMissing()) {
	return getOrgPath(PATH_SETTINGS, orgKey);
}

export function getPathOrgUsers(orgKey=throwIfMissing()) {
	return getOrgPath(PATH_USERS, orgKey);
}

// OTHER PATHS
export function getPathSelectOrg() {                return getPath(PATH_SELECT_ORG); }
