import {
	getAll,
	getSingle,
	createNew,
	updateItem,
	deleteItem,
} from 'utils/api';

import { createWhitelabel } from 'store/modules/org/whitelabels';

import * as labels from 'data/labels';

const END_POINT = 'admin/organizations';
const ACTION_ROOT = 'orgs';

const MSG_SAVE_SUCCESS = `${labels.Organization} Saved`;
const MSG_DELETE_SUCCESS = `${labels.Organization} Deleted`;

/* ACTIONS */
const SET_LIST 								= `${ACTION_ROOT}/SET_LIST`;
const SET_ACTIVE 							= `${ACTION_ROOT}/SET_ACTIVE`;
const CLEAR_ACTIVE 						= `${ACTION_ROOT}/CLEAR_ACTIVE`;
const SET_ACTIVE_EDIT_STATE 	= `${ACTION_ROOT}/SET_ACTIVE_EDIT_STATE`;

const initialState = {
	list: [],
	active: {},
	editState: false,
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_LIST:
			return { ...state, list: action.payload };
		case SET_ACTIVE:
			return { ...state, active: action.payload, editState: false }
		case CLEAR_ACTIVE:
			return { ...state, active: {} }
		case SET_ACTIVE_EDIT_STATE:
			return { ...state, editState: action.payload }
		default:
			return state;
	}
}

export function getAllOrgs( success ) {
	return function( dispatch ) {
		getAll(
			END_POINT,
			dispatch,
			SET_LIST,
			success
		);
	}
}

export function createOrg( data, formName, success ) {
	return function(dispatch) {
		createNew(
			END_POINT,
			data,
			dispatch,
			SET_ACTIVE,
			MSG_SAVE_SUCCESS,
			(response) => {
				const orgKey = response.data.key;

				createWhitelabel( orgKey, {organizationKey:orgKey}, null, () => {
					if (success) {
						success(response);
					}
				})(dispatch);
			},
			SET_LIST,
			formName,
		);
	}
}

export function setActiveOrgEditState( active ) {
	return {
		type: SET_ACTIVE_EDIT_STATE,
		payload: active,
	}
}

export function clearActiveOrg() {
	return {
		type: CLEAR_ACTIVE,
	}
}

export function getActiveOrg( key ) {
	return function( dispatch ) {
		getSingle(
			END_POINT,
			key,
			dispatch,
			SET_ACTIVE
		);
	}
}

export function updateActiveOrg( key, data, formName, success ) {
	return function(dispatch) {
		updateItem(
			END_POINT,
			key,
			data,
			dispatch,
			SET_ACTIVE,
			MSG_SAVE_SUCCESS,
			success,
			SET_LIST,
			formName,
		);
	}
}

export function deleteOrg( key, success ) {
	return function(dispatch) {
		deleteItem(
			END_POINT,
			key,
			dispatch,
			MSG_DELETE_SUCCESS,
			success,
			SET_LIST,
		);
	}
}
