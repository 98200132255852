import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Unauthorized = () => {
	return (
		<Row>
			<Col xs={12} className="text-center">
				<h2 className="text-danger" style={{ margin: "35px 0 15px" }}><em className="fa fa-ban" /></h2>
				<h4 className="text-danger">Unauthorized</h4>
			</Col>
		</Row>
	)
}

export default Unauthorized;
