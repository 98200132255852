import {
	getAll,
	getSingle,
	getData,
	createNew,
	updateItem,
	deleteItem,
	getOrganizationEndpoint,
} from 'utils/api';

import { sortDate, ORDER_ASC } from 'utils/sort';

const END_POINT = 'whitelabels';
const ACTION_ROOT = 'whitelabels';

const MSG_SAVE_SUCCESS = 'Configuration Saved';
const MSG_DELETE_SUCCESS = 'Configuration Deleted';

/* ACTIONS */
const SET_LIST 								= `${ACTION_ROOT}/SET_LIST`;
const SET_ACTIVE 							= `${ACTION_ROOT}/SET_ACTIVE`;
const CLEAR_ACTIVE 						= `${ACTION_ROOT}/CLEAR_ACTIVE`;
const SET_ACTIVE_EDIT_STATE 	= `${ACTION_ROOT}/SET_ACTIVE_EDIT_STATE`;

const initialState = {
	list: [],
	active: {},
	editState: false,
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_LIST:
			return { ...state, list: action.payload };
		case SET_ACTIVE:
			return { ...state, active: action.payload, editState: false }
		case CLEAR_ACTIVE:
			return { ...state, active: {} }
		case SET_ACTIVE_EDIT_STATE:
			return { ...state, editState: action.payload }
		default:
			return state;
	}
}

export function getAllWhitelabels( orgKey, success ) {
	return function( dispatch ) {
		getAll(
			getOrganizationEndpoint( orgKey, END_POINT ),
			dispatch,
			SET_LIST,
			success
		);
	}
}

export function createWhitelabel( orgKey, data, formName, success ) {
	return function(dispatch) {
		createNew(
			getOrganizationEndpoint( orgKey, END_POINT ),
			data,
			dispatch,
			SET_ACTIVE,
			MSG_SAVE_SUCCESS,
			success,
			SET_LIST,
			formName,
		);
	}
}

export function setActiveWhitelabelEditState( active ) {
	return {
		type: SET_ACTIVE_EDIT_STATE,
		payload: active,
	}
}

export function clearActiveWhitelabel() {
	return {
		type: CLEAR_ACTIVE,
	}
}

export function getDefaultWhitelabel( orgKey, success ) {
	return function(dispatch) {
		getData(
			getOrganizationEndpoint( orgKey, END_POINT ),
			null,
			null,
			null,
			(response) => {
				const allWhitelabels = sortDate(response.data, "createdOn", ORDER_ASC);

				dispatch({
					type: SET_ACTIVE,
					payload: allWhitelabels[0],
				});

				if ( success ) {
					success(response, allWhitelabels[0]);
				}
			}
		);
	}
}

export function getActiveWhitelabel( orgKey, key ) {
	return function( dispatch ) {
		if ( key ) {
			getSingle(
				getOrganizationEndpoint( orgKey, END_POINT ),
				key,
				dispatch,
				SET_ACTIVE
			);
		}
	}
}

export function updateActiveWhitelabel( orgKey, key, data, formName, success ) {
	return function(dispatch) {
		updateItem(
			getOrganizationEndpoint( orgKey, END_POINT ),
			key,
			data,
			dispatch,
			SET_ACTIVE,
			MSG_SAVE_SUCCESS,
			success,
			SET_LIST,
			formName,
		);
	}
}

export function deleteWhitelabel( orgKey, key, success ) {
	return function(dispatch) {
		deleteItem(
			getOrganizationEndpoint( orgKey, END_POINT ),
			key,
			dispatch,
			MSG_DELETE_SUCCESS,
			success,
			SET_LIST,
		);
	}
}
