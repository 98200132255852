import { ROLE_ADMIN } from 'data/constants';

export function isAdmin( roles ) {
	if( ! roles ) {
		return false;
	}

	return roles.includes(ROLE_ADMIN);
}

export function getActiveOrgs( orgs ) {
	if ( ! orgs ) {
		return [];
	}

	return orgs.filter( (org) => ! org.deleted )
}

export function isTiedToActiveOrg( orgs, orgKey ) {
	if( !orgs ) {
		return false;
	}

	const activeOrgs = getActiveOrgs( orgs );
	const matchedOrg = activeOrgs.find( (org) => org.key === orgKey );

	if( matchedOrg ) {
		return true;
	}

	return false;
}
