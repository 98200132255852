/* eslint no-console: 0 */

// import {reset} from 'redux-form';
// import { browserHistory } from 'react-router';
// import toastr from 'toastr';

// import { API_URL, SITE_URL, UNKNOWN_ERROR_MSG } from 'jsx/constants';
// import { PATH_PASSWORD, getPathAuthorizedHomeRedirect } from 'jsx/route-utils';
// import { getAuthorizationHeader, isAdmin } from 'jsx/utils';
// import { configurePusher } from 'jsx/classes/Pusher';

import {
	UNKNOWN_ERROR_MSG,
	createNew,
	getData,
	postData,
	putData,
	showSuccessMessage,
	showErrorMessage,
	showInfoMessage,
	handleRequestError,
} from 'utils/api';

import { getCurrentBaseUrl } from 'utils';

export const AUTH_USER = 'auth/AUTH_USER';
const AUTH_ERROR = 'auth/AUTH_ERROR';
const UNAUTH_USER = 'auth/UNAUTH_USER';
const SET_CURRENT_USER = 'auth/SET_CURRENT_USER';
const SET_CURRENT_USER_EDIT_STATE = 'auth/SET_CURRENT_USER_EDIT_STATE';
export const SET_CURRENT_FOUNDATION = 'auth/SET_CURRENT_FOUNDATION';
export const SET_CURRENT_ORG = 'auth/SET_CURRENT_ORG';
export const SET_CURRENT_GIVING_EVENT = 'auth/SET_CURRENT_GIVING_EVENT';
export const SET_CURRENT_GIVING_EVENT_ORG = 'auth/SET_CURRENT_GIVING_EVENT_ORG';
const SET_AVAILABLE_GIVING_EVENTS = 'auth/SET_AVAILABLE_GIVING_EVENTS';
export const SET_CURRENT_LOCATION = 'auth/SET_CURRENT_LOCATION';
const SET_INVITE_TOKEN_ERROR = 'auth/SET_INVITE_TOKEN_ERROR';
const RESET_PASSWORD_TOKEN_ERROR = 'auth/RESET_PASSWORD_TOKEN_ERROR';

const initialState = {
	authenticated: false,
	currentUser: {},
	currentUserEditState: false,
	currentFoundation: {},
	currentOrg: {},
	currentGivingEvent: {},
	currentGivingEventOrg: {},
	availableGivingEvents: [],
	currentLocation: '',
	errorMessage: '',
	resetPasswordTokenError: false,
	inviteTokenError: false,
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case AUTH_USER:
			return { ...state, authenticated: true, errorMessage: '' };
		case UNAUTH_USER:
			return initialState;
		case AUTH_ERROR:
			return { ...state, errorMessage: action.payload };
		case SET_CURRENT_USER:
			return { ...state, currentUser: action.payload, currentUserEditState: false };
		case SET_CURRENT_USER_EDIT_STATE:
			return { ...state, currentUserEditState: action.payload };
		case SET_CURRENT_FOUNDATION:
			return { ...state, currentFoundation: action.payload };
		case SET_CURRENT_ORG:
			return { ...state, currentOrg: action.payload };
		case SET_CURRENT_GIVING_EVENT:
			return { ...state, currentGivingEvent: action.payload };
		case SET_CURRENT_GIVING_EVENT_ORG:
			return { ...state, currentGivingEventOrg: action.payload };
		case SET_AVAILABLE_GIVING_EVENTS:
			return { ...state, availableGivingEvents: action.payload };
		case SET_CURRENT_LOCATION:
			return { ...state, currentLocation: action.payload };
		case RESET_PASSWORD_TOKEN_ERROR:
			return { ...state, resetPasswordTokenError: action.payload };
		case SET_INVITE_TOKEN_ERROR:
			return { ...state, inviteTokenError: action.payload };
		default:
			return state;
	}
}

export function loginUser(data, success) {
	return function( dispatch ) {
		postData(
			'sessions/login',
			data,
			dispatch,
			null,
			(response) => {
				if( response.data.success ) {
					dispatch({ type: AUTH_USER });
					localStorage.setItem( 'token', response.data.sessionToken );

					getCurrentUser( dispatch, success);
				}
				else {
					dispatch( authError( response.data.message ) );
				}
			},
			(error) => {
				let errorMsg = UNKNOWN_ERROR_MSG;
				if( error.response && error.response.data && error.response.data.message ) {
					errorMsg = error.response.data.message;
				}
				dispatch( authError( errorMsg ) );
			},
			{}, 	// NO HEADERS FOR THIS REQUEST
		);
	}
}

export function logoutUser() {
	localStorage.removeItem( 'token' );
	return { type: UNAUTH_USER };
}

export function authError( error ) {
	return {
		type: AUTH_ERROR,
		payload: error
	}
}

export function clearAuthError() {
	return {
		type: AUTH_ERROR,
		payload: '',
	}
}

// export function createUser({ firstName, lastName, emailAddress, password }) {
// 	return function(dispatch) {
// 		postData(
// 			'users',
// 			{
// 				firstName,
// 				lastName,
// 				emailAddress,
// 				password
// 			},
// 			dispatch,
// 			null,
// 			(response) => {
// 				dispatch({ type: AUTH_USER });
// 				localStorage.setItem( 'token', response.data.sessionToken );
//
// 				getCurrentUser( dispatch, (response) => {
// 					// REDIRECT AFTER currentUser IS LOADED
// 					// browserHistory.push( getPathAuthorizedHomeRedirect() );
// 				});
// 			},
// 			(error) => {
// 				let errorMsg = UNKNOWN_ERROR_MSG;
// 				if( error.response && error.response.data && error.response.data.message ) {
// 					errorMsg = error.response.data.message;
// 				}
// 				dispatch( authError( errorMsg ) );
// 			},
// 			{}, 	// NO HEADERS FOR THIS REQUEST
// 		);
// 	}
// }
//

export function getCurrentUser( dispatch, callback, errCallback ) {
	// GET CURRENT USER
	getData(
		'users/current',
		null,
		dispatch,
		SET_CURRENT_USER,
		(response) => {
			if( callback ) {
				callback( response );
			}
		},
		(error) => {
			if( error.response && error.response.data && error.response.data.success === false ) {
				dispatch(	logoutUser() );
			}
			else {
				handleRequestError( error );
			}
			if( errCallback ) {
				errCallback();
			}
		},
	);
}

// export function setCurrentUserEditState( active ) {
// 	return {
// 		type: SET_CURRENT_USER_EDIT_STATE,
// 		payload: active,
// 	}
// }
//
// export function updateCurrentUser( userData, userKey ) {
// 	return function(dispatch) {
// 		putData(
// 			'users',
// 			userKey,
// 			userData,
// 			dispatch,
// 			null,
// 			(response) => {
// 				getCurrentUser( dispatch );
// 				showSuccessMessage("Your profile has been updated.");
// 			},
// 		);
// 	}
// }
//
// export function updateCurrentUserProfilePicture( file, userKey ) {
// 	return function(dispatch) {
// 		postData(
// 			`users/${userKey}/setProfilePicture`,
// 			file,
// 			dispatch,
// 			null,
// 			(response) => {
// 				getCurrentUser( dispatch );
// 				showSuccessMessage("Your profile image has been updated.");
// 			}
// 		);
// 	}
// }
//
// export function setCurrentFoundation( success ) {
// 	return function(dispatch) {
// 		getData(
// 			"cfConfig",
// 			null,
// 			dispatch,
// 			SET_CURRENT_FOUNDATION,
// 			(response) => {
// 				if ( success ) {
// 					success(response);
// 				}
// 			},
// 		);
// 	}
//
// }
//
export function setCurrentOrg( orgKey, success ) {
	return function(dispatch) {
		getData(
			"admin/organizations",
			orgKey,
			dispatch,
			SET_CURRENT_ORG,
			(response) => {
				showInfoMessage(`You are now accessing the system as ${response.data.name}`);

				if ( success ) {
					success(response);
				}
			},
		);
	}
}

export function setCurrentOrgWithOrg( org ) {
	return {
		type: SET_CURRENT_ORG,
		payload: org,
	}
}

export function clearCurrentOrg() {
	return {
		type: SET_CURRENT_ORG,
		payload: {},
	}
}

export function confirmInviteToken( token ) {
	return function(dispatch) {
		getData(
			'users/acceptInvitation',
			token,
			dispatch,
			null,
			(response) => {
				dispatch({
					type: SET_INVITE_TOKEN_ERROR,
					payload: !response.data.success,
				});
			},
			(error) => {
				console.error( error );
				dispatch({
					type: SET_INVITE_TOKEN_ERROR,
					payload: true,
				});
			},
			{}, 	// NO HEADERS FOR THIS REQUEST
		);
	}
}

export function acceptInvite( token, data, success ) {
	return function(dispatch) {
		putData(
			"users/acceptInvitation",
			token,
			data,
			dispatch,
			null,
			(response) => {
				if( response.data.success ) {
					showSuccessMessage("Your account has been created.", "Admin Management");
					if ( success ) {
						success(response);
					}
				}
				else {
					const errorMsg = ( response.data.message ) ? response.data.message : UNKNOWN_ERROR_MSG;
					showErrorMessage(errorMsg);
				}
			},
			handleRequestError,
			{}, 	// NO HEADERS FOR THIS REQUEST
		);
	}
}

export function confirmResetPasswordToken( token ) {
	return function(dispatch) {
		getData(
			'passwords/reset',
			token,
			dispatch,
			null,
			(response) => {
				dispatch({
					type: RESET_PASSWORD_TOKEN_ERROR,
					payload: !response.data.success,
				});
			},
			(error) => {
				console.error( error );
				dispatch({
					type: RESET_PASSWORD_TOKEN_ERROR,
					payload: true,
				});
			},
			{}, 	// NO HEADERS FOR THIS REQUEST
		);
	}
}

export function setPasswordWithToken( token, password, success ) {
	return function(dispatch) {
		putData(
			"passwords/reset",
			token,
			password,
			dispatch,
			null,
			(response) => {
				if ( response.data.success ) {
					showSuccessMessage("You have successfully updated your password.");
					if ( success ) {
						success(response);
					}
				}
				else {
					const errorMsg = ( response.data.message ) ? response.data.message : UNKNOWN_ERROR_MSG;
					showErrorMessage(errorMsg);
					console.error(response);
				}
			},
			handleRequestError,
			{}, 	// NO HEADERS FOR THIS REQUEST
		);
	}
}

export function requestResetPassword( formProps, resetPath, formName ){
	return function(dispatch) {

		const data = {
			emailAddress: formProps.emailAddress,
			url: `${getCurrentBaseUrl()}${resetPath}`,
		}

		createNew(
			'passwords/sendResetEmail',
			data,
			dispatch,
			null,
			"Please check your email for instructions.",
			null,
			null,
			formName,
			handleRequestError,
			{}, 	// NO HEADERS FOR THIS REQUEST
		);
	}
}

// export function setCurrentUserPassword( userKey, data, formName ) {
// 	return function(dispatch) {
// 		postData(
// 			`users/${userKey}/password`,
// 			data,
// 			dispatch,
// 			null,
// 			(response) => {
// 				console.log(response);
// 				if ( response.data.success ) {
// 					resetForm(formName, dispatch);
// 					showSuccessMessage("You have successfully updated your password.");
// 				}
// 				else {
// 					const errorMsg = ( response.data.message ) ? response.data.message : UNKNOWN_ERROR_MSG;
// 					showErrorMessage(errorMsg);
// 					console.error(response);
// 				}
// 			},
// 		);
// 	}
// }
