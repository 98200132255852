/* eslint no-console: 0 */

import axios from 'axios';
import { reset } from 'redux-form';
import toastr from 'toastr';

import { REACT_APP_API_URL } from 'data/runtimeEnvVars';

export const UNKNOWN_ERROR_MSG = 'An unexpected error has occurred.';

function throwIfMissing() {
	throw new Error('Missing parameter');
}

function getUrl( endpoint, key ) {
	return `${ REACT_APP_API_URL }${ getSlashEndPoint(endpoint) }${ appendKey( key ) }`;
}

export function getOrganizationEndpoint( orgKey = throwIfMissing(), endpoint = throwIfMissing() ) {
	return `organizations/${orgKey}/${endpoint}`;
}

export function getOrgWhitelabelEndpoint( orgKey = throwIfMissing(), whitelabelKey = throwIfMissing(), endpoint = throwIfMissing() ) {
	return getOrganizationEndpoint(orgKey, `whitelabels/${whitelabelKey}/${endpoint}`);
}

function getSlashEndPoint( endpoint ) {
	return ( endpoint.charAt( 0 ) === '/' ) ? endpoint : `/${endpoint}`;
}

function appendKey( key ) {
	return (key) ? `/${key}` : '';
}

export function getAuthorizationHeader( sessionToken ) {
	var token = sessionToken + ':';
	var hashed;
	if (window.btoa) {
		hashed = window.btoa(token);
	}
	return "Basic " + hashed;
}

export function getStandardHeaders() {
	return {
		Authorization: getAuthorizationHeader(localStorage.getItem('token')),
	};
}
export function showSuccessMessage( msg ) {
	if( msg ) {
		toastr.success(null, msg);
	}
}

export function showErrorMessage( msg ) {
	if( msg ) {
		toastr.error(msg, "Error");
	}
}

export function showInfoMessage( msg ) {
	if( msg ) {
		toastr.info(null, msg);
	}
}

export function resetForm( formName, dispatch ) {
	if( formName && dispatch ) {
		dispatch( reset(formName) );
	}
}

export function handleRequestError( error ) {
	const NO_INTERNET_MSG = 'You appear to be offline, please check your internet connection.';
	const NO_RESPONSE_MSG = 'No response was included with this request for data.  Please check your internet connection.';

	console.error(error);

	let errorMsg = UNKNOWN_ERROR_MSG;

	if( navigator && navigator.onLine === false ) {
		errorMsg = NO_INTERNET_MSG;
	}
	else if( error ) {
		if( ! error.response ) {
			errorMsg = NO_RESPONSE_MSG;
		}
		else {
			if( error.response.data && error.response.data.message ){
				errorMsg = error.response.data.message;
			}
		}
	}

	showErrorMessage( errorMsg )	;
}

// /* GET FUNCTIONS */
export function getData(
	endpoint = throwIfMissing(),
	key,
	dispatch,
	actionType,
	successFx,
	errorFx = handleRequestError,
	headers = getStandardHeaders(),
){
	// ENDPOINT - STRING : API ENDPOINT
	// KEY - STRING : KEY TO APPEND TO API ENDPOINT
	// DISPATCH - FUNCTION : IF DISPATCH AND ACTIONTYPE ARE SUPPLIED, DISPATCH WILL BE CALLED WITH THE ACTIONTYPE AND RESPONSE.DATA AS PAYLOAD
	// ACTIONTYPE - STRING : IF DISPATCH AND ACTIONTYPE ARE SUPPLIED, DISPATCH WILL BE CALLED WITH THE ACTIONTYPE AND RESPONSE.DATA AS PAYLOAD
	// SUCCESS - FUNCTION : OPTIONAL FUNCTION TO CALL IF REQUEST WAS SUCCESSFUL

	axios.get( getUrl(endpoint, key), { headers } )
	.then( (response) => {
		try {
			if( dispatch && actionType ) {
				dispatch({
					type: actionType,
					payload: response.data,
				});
			}

			if( successFx ) {
				successFx(response);
			}
		}
		catch(error) {
			// RESPONSE PROCESSING ERROR AS OPPOSED TO API REQUEST ERROR
			console.error(error);
		}
	})
	.catch( (error) => {
		errorFx(error);
	});
}

export function getAll(
	endpoint = throwIfMissing(),
	dispatch,
	actionType,
	successFx,
	errorFx,
	headers,
) {
	getData( endpoint, null, dispatch, actionType, successFx, errorFx, headers );
}

export function getSingle(
	endpoint = throwIfMissing(),
	key,
	dispatch,
	actionType,
	successFx,
	errorFx,
	headers,
) {
	getData( endpoint, key, dispatch, actionType, successFx, errorFx, headers );
}

// /* POST FUNCTIONS */
export function postData(
	endpoint = throwIfMissing(),
	data = throwIfMissing(),
	dispatch,
	actionType,
	successFx,
	errorFx = handleRequestError,
	headers = getStandardHeaders(),
){
	// ENDPOINT - STRING : API ENDPOINT
	// DATA - OBJECT : DATA TO SEND IN POST REQUEST
	// DISPATCH - FUNCTION : IF DISPATCH AND ACTIONTYPE ARE SUPPLIED, DISPATCH WILL BE CALLED WITH THE ACTIONTYPE AND RESPONSE.DATA AS PAYLOAD
	// ACTIONTYPE - STRING : IF DISPATCH AND ACTIONTYPE ARE SUPPLIED, DISPATCH WILL BE CALLED WITH THE ACTIONTYPE AND RESPONSE.DATA AS PAYLOAD
	// SUCCESS - FUNCTION : OPTIONAL FUNCTION TO CALL IF REQUEST WAS SUCCESSFUL

	axios.post( getUrl(endpoint), data, { headers }	)
	.then( (response) => {
		try {
			if( dispatch && actionType ) {
				dispatch({
					type: actionType,
					payload: (response.data.data) ? response.data.data : response.data,
				});
			}

			if( successFx ) {
				successFx(response);
			}
		}
		catch(error) {
			// RESPONSE PROCESSING ERROR
			console.error(error);
		}
	})
	.catch( (error) => {
		// API REQUEST ERROR
		errorFx(error);
	});
}

export function createNew(
	endpoint = throwIfMissing(),
	data = throwIfMissing(),
	dispatch,
	actionType,
	successMsg,
	successFx,
	refreshListActionType,
	formName,
	errorFx,
	headers,
){
	postData(
		endpoint,
		data,
		dispatch,
		actionType,
		(response) => {
			showSuccessMessage( successMsg );
			resetForm( formName, dispatch );

			if( refreshListActionType ) {
				getAll( endpoint, dispatch, refreshListActionType );
			}

			if( successFx ) {
				successFx(response);
			}
		},
		errorFx,
		headers,
	);
}

// /* PUT FUNCTIONS */
export function putData(
	endpoint = throwIfMissing(),
	key,
	data,
	dispatch,
	actionType,
	successFx,
	errorFx = handleRequestError,
	headers = getStandardHeaders(),
){
	// ENDPOINT - STRING : API ENDPOINT
	// KEY - STRING : KEY TO APPEND TO API ENDPOINT
	// DATA - OBJECT : DATA TO SEND IN PUT REQUEST
	// DISPATCH - FUNCTION : IF DISPATCH AND ACTIONTYPE ARE SUPPLIED, DISPATCH WILL BE CALLED WITH THE ACTIONTYPE AND RESPONSE.DATA AS PAYLOAD
	// ACTIONTYPE - STRING : IF DISPATCH AND ACTIONTYPE ARE SUPPLIED, DISPATCH WILL BE CALLED WITH THE ACTIONTYPE AND RESPONSE.DATA AS PAYLOAD
	// SUCCESS - FUNCTION : OPTIONAL FUNCTION TO CALL IF REQUEST WAS SUCCESSFUL

	axios.put( getUrl(endpoint, key), data, { headers } )
	.then( (response) => {
		try {
			if( dispatch && actionType ) {
				dispatch({
					type: actionType,
					payload: (response.data.data) ? response.data.data : response.data,
				});
			}

			if( successFx ) {
				successFx(response);
			}
		}
		catch(error) {
			// RESPONSE PROCESSING ERROR
			console.error(error);
		}
	})
	.catch( (error) => {
		// API REQUEST ERROR
		errorFx(error);
	});
}

export function updateItem(
	endpoint = throwIfMissing(),
	key,
	data = throwIfMissing(),
	dispatch,
	actionType,
	successMsg,
	successFx,
	refreshListActionType,
	formName,
	errorFx,
	headers,
){
	putData(
		endpoint,
		key,
		data,
		dispatch,
		actionType,
		(response) => {
			showSuccessMessage( successMsg );
			resetForm( formName, dispatch );

			if( refreshListActionType ) {
				getAll( endpoint, dispatch, refreshListActionType );
			}

			if( successFx ) {
				successFx(response);
			}
		},
		errorFx,
		headers,
	);
}

// /* DELETE FUNCTIONS */
export function deleteData(
	endpoint = throwIfMissing(),
	key = throwIfMissing(),
	dispatch,
	successFx,
	errorFx = handleRequestError,
	headers = getStandardHeaders(),
){
	// ENDPOINT - STRING : API ENDPOINT
	// KEY - STRING : KEY TO APPEND TO API ENDPOINT
	// DISPATCH - FUNCTION : IF DISPATCH AND ACTIONTYPE ARE SUPPLIED, DISPATCH WILL BE CALLED WITH THE ACTIONTYPE AND RESPONSE.DATA AS PAYLOAD
	// SUCCESS - FUNCTION : OPTIONAL FUNCTION TO CALL IF REQUEST WAS SUCCESSFUL

	axios.delete( getUrl(endpoint, key), { headers } )
	.then( (response) => {
		try {
			if( successFx ) {
				successFx(response);
			}
		}
		catch(error) {
			// RESPONSE PROCESSING ERROR
			console.error(error);
		}
	})
	.catch( (error) => {
		// API REQUEST ERROR
		errorFx(error);
	});
}

export function deleteItem(
	endpoint = throwIfMissing(),
	key = throwIfMissing(),
	dispatch,
	successMsg,
	successFx,
	refreshListActionType,
	errorFx,
	headers,
){
	deleteData(
		endpoint,
		key,
		dispatch,
		(response) => {
			showSuccessMessage( successMsg );

			if( refreshListActionType ) {
				getAll( endpoint, dispatch, refreshListActionType );
			}

			if( successFx ) {
				successFx(response);
			}
		},
		errorFx,
		headers,
	);
}

// /* UPLOAD FILE TO AWS */
export function uploadFileToAWS(
	uploadUri = throwIfMissing(),
	file = throwIfMissing(),
	successFx,
	errorFx = handleRequestError,
) {

	axios.put(
		uploadUri,
		file,
		{
			headers: { "Content-Type": file.type, },
		}
	)
	.then( (response) => {
		try {
			if( successFx ) {
				successFx(response);
			}
		}
		catch(error) {
			// RESPONSE PROCESSING ERROR
			console.error(error);
		}
	})
	.catch( (error) => {
		// API REQUEST ERROR
		errorFx(error);
	});
}

export function getUriAndUploadToAWS(
	endpoint = throwIfMissing(),
	file = throwIfMissing(),
	successFx,
	errorFx = handleRequestError,
) {

	postData(
		endpoint,
		{
			filename: file.name,
			key: file.key,
			mimeType: file.type,
		},
		null,
		null,
		(response) => {
			// RETRIEVE UPLOAD URI FROM RESPONSE
			const { uploadUri } = response.data;

			// UPLOAD FILE TO AWS
			uploadFileToAWS(
				uploadUri,
				file,
				successFx,
				errorFx,
			);
		},
		errorFx
	);
}
