import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Unauthorized from 'components/Pages/Authorized/Unauthorized';

import { isAdmin, isTiedToActiveOrg } from 'utils/user';

import {
	getPathLogin,
	getPathAdminHome,
} from 'Routes/paths';

export const BYPASS_IF_AUTH = "BYPASS_IF_AUTH";
export const REQUIRE_AUTH = "REQUIRE_AUTH";
export const REQUIRE_ADMIN = "REQUIRE_ADMIN";
export const REQUIRE_ORG = "REQUIRE_ORG";

export const PageAuth = (AuthType) => (ComposedComponent) => {
	class AuthRoleCheck extends Component {
		static propTypes = {
			match: PropTypes.shape({
				params: PropTypes.shape({
					orgKey: PropTypes.string,
				}),
			}),
		};

		static contextTypes = {
			router: PropTypes.object
		}

		constructor(props) {
			super(props);

			this.state = {
				userAuthorized: true,
			}
		}

		componentDidMount() {
			this.checkAuth( this.props );
		}

		checkAuth(props) {
			switch (AuthType) {
				case BYPASS_IF_AUTH:
					this.bypassIfAuth(props);
					break;
				case REQUIRE_AUTH:
					this.requireAuth(props);
					break;
				case REQUIRE_ADMIN:
					this.requireAdmin(props);
					break;
				case REQUIRE_ORG:
					this.requireOrg(props);
					break;
				default:
					break;
			}
		}

		setUserAuthorizedState( value ) {
			if( this.state.userAuthorized !== value ) {
				this.setState({ userAuthorized: value });
			}
		}

		isAuthenticated({ authenticated }) {
			if( authenticated ) {
				return true;
			}
			else {
				return false;
			}
		}

		isCurrentUserAdmin({ currentUser }) {
			return isAdmin( currentUser.roles );
		}

		isCurrentUserTiedToOrg({ currentUser, match }) {
			const { orgKey } = match.params;

			if( ! orgKey ) {
				return true;
			}

			return isTiedToActiveOrg( currentUser.organizations, orgKey );
		}

		bypassIfAuth(props) {
			if ( this.isAuthenticated(props) ) {
				this.redirectToAuthenticatedHome();
				return;
			}
		}

		requireAuth(props) {
			if( ! this.isAuthenticated(props) ) {
				this.redirectToLogin();
				return;
			}
		}

		requireAdmin(props) {
			if( ! this.isAuthenticated(props) ) {
				this.redirectToLogin();
				return;
			}

			this.setUserAuthorizedState( this.isCurrentUserAdmin(props) );
		}

		requireOrg(props) {
			if( ! this.isAuthenticated(props) ) {
				this.redirectToLogin();
				return;
			}

			this.setUserAuthorizedState( this.isCurrentUserAdmin(props) || this.isCurrentUserTiedToOrg(props) );
		}

		redirectToLogin() {
			this.props.history.push( getPathLogin() );
		}

		redirectToAuthenticatedHome() {
			this.props.history.push( getPathAdminHome() );
		}

		render() {
			const { userAuthorized } = this.state;

			if ( ! userAuthorized ) {
				return <Unauthorized />;
			}

			return <ComposedComponent {...this.props} />;
		}
	}

	function mapStateToProps(state) {
		return {
			authenticated: state.auth.authenticated,
			currentUser: state.auth.currentUser,
		};
	}

	return connect(mapStateToProps)(AuthRoleCheck);
}

export const BypassIfAuth = PageAuth(BYPASS_IF_AUTH);
export const RequireAuth = PageAuth(REQUIRE_AUTH);
export const RequireAdmin = PageAuth(REQUIRE_ADMIN);
export const RequireOrg = PageAuth(REQUIRE_ORG);
