import { ParamsInit } from './ParamsInit';

import {
	BypassIfAuth,
	RequireAuth,
	RequireAdmin,
	RequireOrg,
} from './PageAuth';


export const PageInit = (ComposedComponent) => {
	return ParamsInit(ComposedComponent);
}

export const BypassIfAuthInit = (ComposedComponent) => {
	let Page = PageInit(ComposedComponent);
	Page     = BypassIfAuth(Page);
	return Page;
}

export const AuthPageInit = (ComposedComponent) => {
	let Page = PageInit(ComposedComponent);
	Page     = RequireAuth(Page);
	return Page;
}

export const AdminPageInit = (ComposedComponent) => {
	let Page = PageInit(ComposedComponent);
	Page     = RequireAdmin(Page);
	return Page;
}

export const OrgPageInit = (ComposedComponent) => {
	let Page = PageInit(ComposedComponent);
	Page     = RequireOrg(Page);
	return Page;
}
