/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { formatFullName } from 'utils';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'store/actions/actions';

class Header extends Component {

get currentUserName() {
	const {
		firstName,
		lastName,
		correspondenceEmailAddress,
	} = this.props.currentUser;

	const fullName = formatFullName(firstName, lastName);

	if ( fullName ) {
		return fullName;
	}

	return correspondenceEmailAddress;
}

toggleUserblock = e => {
	e.preventDefault();
	this.props.actions.toggleSetting('showUserBlock');
}

toggleOffsidebar = e => {
	e.preventDefault()
	this.props.actions.toggleSetting('offsidebarOpen');
}

toggleCollapsed = e => {
	e.preventDefault()
	this.props.actions.toggleSetting('isCollapsed');
	this.resize()
}

toggleAside = e => {
	e.preventDefault()
	this.props.actions.toggleSetting('asideToggled');
}

resize () {
	// all IE friendly dispatchEvent
	var evt = document.createEvent('UIEvents');
	evt.initUIEvent('resize', true, false, window, 0);
	window.dispatchEvent(evt);
	// modern dispatchEvent way
	// window.dispatchEvent(new Event('resize'));
}

render() {
	const {
		currentUserName,
		props: {
			currentOrg,
		},
	} = this;

	return (
		<header className="topnavbar-wrapper">
			{ /* START Top Navbar */ }
			<nav className="navbar topnavbar">
				{ /* START navbar header */ }
				<div className="navbar-header">
					<Link className="navbar-brand" to="/">
						<div className="brand-logo">
							<img className="img-fluid" src="/img/logo.png" alt="App Logo" style={{ height: "34px" }} />
						</div>
						<div className="brand-logo-collapsed">
							<img className="img-fluid" src="/img/logo-single.png" alt="App Logo" style={{ height: "34px" }} />
						</div>
					</Link>
				</div>
				{ /* END navbar header */ }

				{ /* START Left navbar */ }
				<ul className="navbar-nav mr-auto flex-row align-items-center">
					<li className="nav-item">
						{ /* Button used to collapse the left sidebar. Only visible on tablet and desktops */ }
						<a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={ this.toggleCollapsed }>
							<em className="fas fa-bars"></em>
						</a>
						{ /* Button to show/hide the sidebar on mobile. Visible on mobile only. */ }
						<a href=""  className="nav-link sidebar-toggle d-md-none" onClick={ this.toggleAside }>
							<em className="fas fa-bars"></em>
						</a>
					</li>
					<li className="current-org">
						{ currentOrg.name }
					</li>
				</ul>
				{ /* END Left navbar */ }
				{ /* START Right Navbar */ }
				<ul className="navbar-nav flex-row">
					<li className="current-user">
						<span className="fas fa-user-circle" />
						{' '}
						{ currentUserName }
					</li>
				</ul>
				{ /* END Right Navbar */ }

				{ /* START Search form */ }
				<form className="navbar-form" role="search" action="search.html">
					<div className="form-group">
						<input className="form-control" type="text" placeholder="Type and hit enter ..."/>
						<div className="fa fa-times navbar-form-close" data-search-dismiss=""></div>
					</div>
					<button className="d-none" type="submit">Submit</button>
				</form>
				{ /* END Search form */ }
			</nav>
			{ /* END Top Navbar */ }
		</header>
		);
}

}

Header.propTypes = {
actions: PropTypes.object,
settings: PropTypes.object
};

const mapStateToProps = state => ({
	settings: state.settings,
	currentUser: state.auth.currentUser,
	currentOrg: state.auth.currentOrg,
})

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
mapStateToProps,
mapDispatchToProps
)(Header);
