import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import settingsReducer from './settings.reducer.js';
import themesReducer from './themes.reducers.js';

import auth from '../modules/auth';
import orgDonations from '../modules/org/donations';
import orgPrograms from '../modules/org/programs';
import orgWhitelabels from '../modules/org/whitelabels';
import orgs from '../modules/admin/orgs';
import users from '../modules/users';

export default combineReducers({
	auth,
	form,
	orgDonations,
	orgPrograms,
	orgWhitelabels,
	orgs,
	users,
	settings: settingsReducer,
	theme: themesReducer,
});
