import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';

import reducers from './reducers/reducers';
import middlewares from './middlewares/middlewares'

// TODO: this persists the selected theme settings, but was also persisting all state including form fields, can this be made smarter?
// import { updateTheme } from './middlewares/themes.middleware.js';
// import { persistedState, saveState } from './persisted.store.js';

export default function configureStore() {

	const store = createStore(
		reducers,
		// persistedState, // second argument overrides the initial state
		applyMiddleware(
			...middlewares,
			reduxThunk
		)
	);

	// TODO: this persists the selected theme settings, but was also persisting all state including form fields, can this be made smarter?
	// // add a listener that will be invoked on any state change
	// store.subscribe(() => {
	// 	saveState(store.getState());
	// });
	//
	// // Update the initial theme
	// updateTheme(store.getState())

	return store;

}
