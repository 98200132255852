import * as paths from 'Routes/paths';
import * as labels from 'data/labels';

export const adminMenu = [
		{
        name: labels.Organizations,
        path: paths.getPathOrganizations(),
        icon : 'fas fa-fw fa-sitemap',
    },
		{
        name: 'Admins',
        path: paths.getPathSystemAdmins(),
        icon : 'far fa-fw fa-id-badge',
    },
    // {
    //     name: 'Menu',
    //     icon: 'icon-speedometer',
    //     translate: 'sidebar.nav.MENU',
    //     label: { value: 1, color: 'info' },
    //     submenu: [{
    //         name: 'Submenu',
    //         translate: 'sidebar.nav.SUBMENU',
    //         path: '/submenu'
    //     }]
    // },
];

export const orgMenu = [
	// {
	// 	name: 'Donations',
	// 	orgPath: paths.getPathOrgDonations,
	// 	icon : 'fas fa-fw fa-money-bill',
	// },
	{
		name: 'Programs',
		orgPath: paths.getPathOrgPrograms,
		icon : 'fas fa-fw fa-gift',
	},
	{
		name: 'Content',
		orgPath: paths.getPathOrgContent,
		icon : 'fas fa-fw fa-align-justify',
	},
	{
		name: 'Colors',
		orgPath: paths.getPathOrgColors,
		icon : 'fas fa-fw fa-palette',
	},
	{
		name: 'Settings',
		orgPath: paths.getPathOrgSettings,
		icon : 'fas fa-fw fa-cog',
	},
	{
		name: 'Users',
		orgPath: paths.getPathOrgUsers,
		icon : 'fas fa-fw fa-users',
	},
]
