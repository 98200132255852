import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import * as actions from 'store/actions/actions';
import * as labels from 'data/labels';

import SidebarUserBlock from './SidebarUserBlock';
import Menu from './Menu';

import { adminMenu, orgMenu } from 'data/Menu.js';
import { isAdmin } from 'utils/user';
import { getActiveOrgs } from 'utils/org';
import { getPathLogout, getPathSelectOrg } from 'Routes/paths';

class Sidebar extends Component {

    state = {
        collapse: {}
    }

		get showChangeOrg() {
			const {
				roles,
				organizations,
			} = this.props.currentUser;

			if (isAdmin(roles)) {
				return false;
			}

			const activeOrgs = getActiveOrgs(organizations);

			if ( activeOrgs.length < 2 ) {
				return false;
			}

			return true;
		}

		get menuData() {
			const {
				showChangeOrg,
				props: {
					currentOrg,
					currentUser,
				},
			} = this;

			let workingMenu = [];

			if (currentOrg.key) {
				workingMenu.push({
					heading: currentOrg.name,
				});

				orgMenu.forEach((item) => {
					const {
						name,
						orgPath,
					} = item;

					const path = (orgPath) ? orgPath(currentOrg.key) : null;

					workingMenu.push({
						...item,
						path,
						isOpen: this.state.collapse[name],
						isActive: this.routeActive(path),
					});
				});
			}
			if (isAdmin(currentUser.roles)) {
				workingMenu.push({ heading: "System Config" });

				 adminMenu.forEach((item) => {
					workingMenu.push({
						...item,
						isOpen: this.state.collapse[item.name],
						isActive: this.routeActive(item.path),
					});
				});
			}

			if( workingMenu.length ) {
				workingMenu.push({ heading: <hr /> });
			}

			if ( showChangeOrg ) {
				workingMenu.push({
					name: `Change ${labels.Organization}`,
	        path: getPathSelectOrg(),
	        icon : 'fa fa-fw fa-angle-double-right',
				})
			}

			workingMenu.push({
				name: 'Logout',
        path: getPathLogout(),
        icon : 'fa fa-fw fa-sign-out-alt',
			})

			return workingMenu;
		}

    componentDidMount() {
        // prepare the flags to handle menu collapsed states
        this.buildCollapseList()

        // Listen for routes changes in order to hide the sidebar on mobile
        this.props.history.listen(this.closeSidebar);
    }

    closeSidebar = () => {
        this.props.actions.toggleSetting('asideToggled');
    }

    /** prepare initial state of collapse menus. Doesnt allow same route names */
    buildCollapseList = () => {
        let collapse = {};

				const menuData = [
					...adminMenu,
				];

				const collapsibleItems = menuData.filter(({heading}) => !heading);


        collapsibleItems.forEach(({name, path, submenu}) => {
        	collapse[name] = this.routeActive(submenu ? submenu.map(({path})=>path) : path)
        });

        this.setState({collapse});
    }

    navigator = route => {
        this.props.history.push(route);
    }

    routeActive(paths) {
        paths = Array.isArray(paths) ? paths : [paths];
        return paths.some(p => this.props.location.pathname.indexOf(p) > -1)
    }

    toggleItemCollapse(stateName) {
        for (let c in this.state.collapse) {
            if (this.state.collapse[c] === true && c !== stateName)
                this.setState({
                    collapse: {
                        [c]: false
                    }
                });
        }
        this.setState({
            collapse: {
                [stateName]: !this.state.collapse[stateName]
            }
        });
    }

    getSubRoutes = item => item.submenu.map(({path}) => path)

    render() {
				const {
					menuData,
				} = this;

        return (
            <aside className='aside-container'>
                { /* START Sidebar (left) */ }
                <div className="aside-inner">
                    <nav data-sidebar-anyclick-close="" className="sidebar">
                        { /* START sidebar nav */ }
                        <ul className="sidebar-nav">
                            { /* START user info */ }
                            <li className="has-user-block">
                                <SidebarUserBlock/>
                            </li>
                            { /* END user info */ }

														<Menu data={ menuData } />
                        </ul>
                        { /* END sidebar nav */ }
                    </nav>
                </div>
                { /* END Sidebar (left) */ }
            </aside>
        );
    }
}

Sidebar.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({
	settings: state.settings,
	currentOrg: state.auth.currentOrg,
	currentUser: state.auth.currentUser,
})

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Sidebar));
